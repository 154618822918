import * as htmx from "htmx.org";
          document.addEventListener('astro:after-swap', () => {
            htmx.process(document.body)
          })
import Alpine from 'alpinejs';
import { setup } from 'virtual:@astrojs/alpinejs/entrypoint';
setup(Alpine);
window.Alpine = Alpine;
Alpine.start();
import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://463a2cc22245cfede2406e0623a9ee3a@o4507017833414656.ingest.us.sentry.io/4507017840558080",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()